<template>
    <div class="home">
        <NavView></NavView>
        <sideBox></sideBox>
        <!-- <button style="position: absolute; top: 0; background-color: aqua; z-index: 999999;" @click="currentPage= 2">asdqweqda</button> -->
        <mv-full-page :isPointer="false" :isV="isV" pages="5" :page.sync="currentPage" :bgArr="bgArr" :isCache="true"
            :transition="{
                duration: '2000ms', // 动画时长
                timingFun: 'ease', // 动画速度曲线
                delay: '0s', // 动画延迟
            }" @rollEnd="testEnd">
            <template #page1>
                <!-- 页面1 -->
                <div class="video-container">
                    <video class="videoPlayer" autoplay muted loop>
                        <source src="http://218.92.39.186:8086/profile/media/home.mp4" type="video/mp4">
                    </video>
                    <div class="msgTab">
                        <div class="msgItem">
                            <div class="left">
                                <img src="../img/组 18.png" alt="">
                            </div>
                            <div class="right">
                                <h2>{{ weatherData.weather }}
                                    <!-- <img class="headImg" :src="require(`../img/${lang}Enter.png`)" alt=""> -->
                                    <img class="sun" src="../img/sun.png" v-show="weatherData.weather.indexOf('晴') != -1">
                                    <img class="sun" src="../img/clouds.png"
                                        v-show="weatherData.weather.indexOf('云') != -1">
                                    <img class="sun" src="../img/clouder.png"
                                        v-show="weatherData.weather.indexOf('阴') != -1">
                                    <img class="sun" src="../img/rain.png" v-show="weatherData.weather.indexOf('雨') != -1">
                                    <img class="sun" src="../img/雾.png" v-show="weatherData.weather.indexOf('雾') != -1">
                                    {{ weatherData.temperature }}
                                </h2>
                                <span>景区天气</span>
                            </div>
                        </div>
                        <div class="msgItem" @click="openDetail">
                            <div class="left">
                                <img src="../img/组 18(1).png" alt="">
                            </div>
                            <div class="right">
                                <h2 v-if="hotTime">07:00~18:00</h2>
                                <h2 v-else>08:00~17:00</h2>
                                <span>开放时间  <span class="timeDetail">详情>></span></span>
                            </div>
                        </div>
                        <div class="msgItem">
                            <div class="left">
                                <img src="../img/组 18(3).png" alt="">
                            </div>
                            <div class="right" @click="toTargetPage">
                                <h2 style="color: #FE8300;">门票预约</h2>
                                <span>景区门票一键预定</span>
                            </div>
                        </div>
                        <div class="msgItem">
                            <div class="left">
                                <img src="../img/walk.png" alt="">
                            </div>
                            <div class="right">
                                <h2>动态游客量</h2>
                                <div class="showTrans">
                                    <div class="trasBox">
                                        <span>景区今日最大承载量 <span style="color: #FE8800;font-weight: 600;">{{dynamicTourist.maximumNum}}万</span></span>
                                        <span>瞬时最大承载量 <span style="color: #FE8800;font-weight: 600;">{{dynamicTourist.instantaneousMaximumNum}}万</span></span>
                                        <span>，景区在园人数{{dynamicTourist.inParkNum}}人，舒适度为{{dynamicTourist.comfortLev}}，预计明天接待游客：{{dynamicTourist.reservationNum}}人。</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="scroll">
                            <div class="msgBox">
                                <div class="arror">
                                    <img src="../img/图层 963 拷贝.png" alt="">
                                    <img src="../img/图层 963.png" alt="">
                                </div>
                                <p>鼠标向下滚动</p>
                                <img class="mouse" src="../img/mouse.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #page2>
                <div class="page2">
                    <!-- 页面2 -->
                    <div class="titleBox" style="margin-top: 3vw;">
                        <h2>走<p></p>进<p></p>花<p></p>果<p></p>山</h2>
                        <!-- <span>ABOUT HUAGUO MOUNTAIN</span> -->
                    </div>
                    <div class="secondBox">
                        <div class="boxLeft">
                            <div class="leftTitle">
                                <img src="../img/图层 969 拷贝.png" alt="">
                                <p>景区概况</p>
                            </div>
                            <div class="leftMsg">
                                <p style="text-indent: 2vw;">
                                    花果山风景区是国家重点风景名胜区、国家AAAAA级旅游区国家地质公园、全国文明风景旅游区示范点、全国文明风景旅游区创建先进单位、全国青年文明号全国重点风景名胜区综合整治先进单位,中国十佳旅游景区、中国最值得外国人去的50个地方金奖、全国“黄金周”旅游直报点和全国空气质量预报系统点。
                                </p>
                                <p style="text-indent: 2vw;">
                                    花果山风景区所在的连云港市位于我国万里海疆的中部，江苏省的东北部，东临黄海，西接中原，北扼齐鲁，南达江淮，与日本及朝鲜半岛隔海相望。景区面积75.39平方公里，层峦叠嶂136峰，其中花果山玉女峰是江苏省最高峰，海拔624.4米，峭壁悬崖，巍峨壮观。
                                </p>
                            </div>
                            <div class="learnMore" @click="toDetail">了解详情></div>
                        </div>
                        <div class="boxRight">
                            <div class="leftTitle">
                                <img src="../img/图层 969 拷贝.png" alt="">
                                <p>景区视频</p>
                            </div>
                            <video class="smallVideo" src="http://218.92.39.186:8086/profile/media/home2.mp4" controls></video>
                        </div>
                    </div>
                </div>
            </template>
            <template #page3>
                <div class="page3">
                    <!-- 页面3 -->
                    <div class="titleBox" style="margin-bottom: 1.5vw;">
                        <h2>畅<p></p>游<p></p>花<p></p>果<p></p>山</h2>
                        <!-- <span>ABOUT HUAGUO MOUNTAIN</span> -->
                    </div>
                    <div class="tabBox">
                        <!-- <div class="tabItem" v-for="(item, index) in tabArr" :key="index"> -->
                        <div class="tabItem" v-for="(item, index) in tabArr" :key="index" @mouseenter="changeTab(index)"
                            @click="toPages(index)">
                            <img :src="require('../img/' + item.changeUrl)" alt="" v-if="tabIndex == index">
                            <img :src="require('../img/' + item.imgUrl)" alt="" v-else>
                            <p :style="{ color: tabIndex == index ? item.color : '#FDFCFB' }">{{ item.value }}</p>
                        </div>
                    </div>

                    <div class="leftArr" @click="leftScroll">
                        <img src="../img/左.png" alt="">
                    </div>
                    <div class="rightArr" @click="rightScroll">
                        <img src="../img/右.png" alt="">
                    </div>
                    <div class="lscrollBox">
                        <div class="thirdBox">
                            <div class="boxItem" v-for="(item, index) in msgList.slice(0, 6)" :key="index">
                                <img :src="item.coverImage" alt="">
                                <h2>{{ item.title }}</h2>
                                <p>{{ item.description }}</p>
                                <div class="thirdMore" @click="toDetails(item, index)">查看更多>></div>
                            </div>
                        </div>

                    </div>
                </div>
            </template>
            <template #page4>
                <div class="page4">
                    <!-- 页面4 -->
                    <div class="titleBox" style="margin-bottom: 1.5vw;opacity: 1; margin-top: 0;">
                        <h2>文<p></p>化<p></p>花<p></p>果<p></p>山</h2>
                        <!-- <span>ABOUT HUAGUO MOUNTAIN</span> -->
                    </div>
                    <div class="fourBox">
                        <div class="fourItem" v-for="(item, index) in  squireList " :key="index" ref="fourItem"
                            @click="toCulture(item)">
                            <!-- <div class="itemBox" :style="{ backgroundImage: `url(${item.coverImage})` }"> -->
                            <div class="itemBox" style="position: relative;">
                                <!-- <img src="../img/999" alt=""> -->
                                <img :src="item.coverImage" alt=""
                                    style="width: 165%; height: 160%;position: absolute; top: -4vw;
                                left: -4vw; transform: rotateZ(45deg);display: flex;align-items: center;justify-content: center;">
                                <p class="itemP" :style="{ backgroundImage: `url(${item.url})` }">{{ item.title
                                }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #page5>
                <div class="page5">
                    <div class="pageMsg">
                        <div class="titleBox" style="padding-top: 10vw;margin-bottom: 1.5vw;">
                            <h2 style="color: #fff;">景<p></p>区<p></p>资<p></p>讯</h2>
                            <!-- <span style="color: #fff;">SCENIC AREA NEWS</span> -->
                        </div>
                        <div class="fifthBox">
                            <div class="fifthItem" v-for="(item, index) in newsArea" :key="index"
                                @click="itemDetail(item, index)">
                                <div class="itemLeft">
                                    <p class="day">{{ item.day }}</p>
                                    <p class="month">{{ item.mounth }}</p>
                                </div>
                                <div class="itemright">
                                    <p class="title">{{ item.title }}</p>
                                    <p class="tips">{{ item.description }}...</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- <img src="@/img/底.png" alt=""> -->
                    <footerBox></footerBox>
                </div>

            </template>
        </mv-full-page>
    </div>
</template>
  
<script>
import axios from 'axios';
import NavView from '../components/Pcnav.vue'
import sideBox from '../components/side.vue'
import footerBox from '../components/Pcfooter.vue'
// import getAtl
import { getAtl } from '@/api/apis';
export default {
    components: {
        NavView,
        sideBox,
        footerBox
    },
    data() {
        return {
            isV: true,
            currentPage: 1,
            tabIndex: 0,
            shangeShow: true,
            dynamicTourist:{},//动态游客值
            bgArr: [{
                isBg: false,
                src: require('@/img/大图.png')
            }, {
                isBg: true,
                src: require('@/img/seconBack.png')
            }, {
                isBg: true,
                src: require('@/img/thirdBack.png')
            }, {
                isBg: true,
                src: require('@/img/fourPage.png')
            }, {
                isBg: false,
                src: require('@/img/fifthBack.png')
            }],
            tabArr: [
                {
                    imgUrl: 'ifood.png',
                    changeUrl: 'foodColor.png',
                    value: '美食',
                    color: '#F5D38F'
                },
                {
                    imgUrl: 'ibed.png',
                    changeUrl: 'bedColor.png',
                    value: '住宿',
                    color: '#F5D38F'
                },
                {
                    imgUrl: 'ibus.png',
                    changeUrl: 'busColor.png',
                    value: '交通',
                    color: '#F5D38F'
                },
                {
                    imgUrl: 'imountain.png',
                    changeUrl: 'mountainColor.png',
                    value: '游玩',
                    color: '#F5D38F'
                },
                {
                    imgUrl: 'ileave.png',
                    changeUrl: 'leaveColor.png',
                    value: '特产',
                    color: '#F5D38F'
                },
                {
                    imgUrl: 'iplay.png',
                    changeUrl: 'playColor.png',
                    value: '娱乐',
                    color: '#F5D38F'
                },

            ],
            msgList: [

            ],
            squireList: [
                {
                    value: '仙国神山何处是',
                    url: require('../img/999-1.png')
                },
                {
                    value: '解读孙悟空',
                    url: require('../img/999-2.png')
                },
                {
                    value: '迎宾献词',
                    url: require('../img/999-3.png')
                },
                {
                    value: '孙悟空为什么大闹天宫',
                    url: require('../img/999-4.png')
                },
                {
                    value: '花果山人文情怀',
                    url: require('../img/999-5.png')
                }
            ],
            weatherData: {
                weather: '',
                temperature: ''
            },
            weatherImg: '',
            // 美食数据
            foodArr: [],
            // 住宿数据
            bedArr: [],
            //交通
            busArr: [],
            // 游玩
            outingArr: [],
            // 特产
            goodsArr: [],
            // 娱乐
            playArr: [],
            newsArea: [],
            aniNum: 1,
            scrollNum: 0,

            hotTime:false

        };
    },
    beforeMount() {
        axios.get("https://restapi.amap.com/v3/weather/weatherInfo?key=033ff747e4b54a631e2df3edec172534&city=320700&extensions=base").then((res) => {
            let data = res.data.lives[0]
            this.weatherData.weather = data.weather
            this.weatherData.temperature = data.temperature + '℃'
        })
        const currentDate = new Date();

        // 获取当前月份和日期
        const currentMonth = currentDate.getMonth() + 1; // 月份从0开始，所以需要加1
        const currentDay = currentDate.getDate();

        // 判断日期是否在3月1日和11月30日之间
        if (
            (currentMonth === 3 && currentDay >= 1) || // 3月1日及以后
            (currentMonth > 3 && currentMonth < 11) || // 4月到10月
            (currentMonth === 11 && currentDay <= 30) // 11月30日及以前
        ) {
            this.hotTime = true
        } else {
            this.hotTime = false

        }

    },
    mounted() {
        sessionStorage.setItem('lang', 0)
        this.getAtcData()
        this.scrollLinster();
        this.getDynamicTourist()
        let fourItem = this.$refs.fourItem
        fourItem[1].style.left = '22.5vw'
        fourItem[2].style.left = '44.5vw'
        fourItem[3].style.left = '11.2vw'
        fourItem[3].style.top = '11vw'
        fourItem[4].style.left = '33.4vw'
        fourItem[4].style.top = '11vw'



        setTimeout(() => {
            let test = sessionStorage.getItem('hisPage')
            this.currentPage = test == null ? 1 : test * 1
        }, 100);
    },
    methods: {
        openDetail() {
            this.$router.push('/openTime')
        },
        toTargetPage(){
            window.open('https://wap.lotsmall.cn/vue/?m_id=1995',"_blank")
        },
        testEnd(page) {
            // console.log('end', page);
            sessionStorage.setItem('hisPage', page)

        },
        getAtcData() {
            let dataArr = []

            if (sessionStorage.getItem('dataArr') == undefined) {
                getAtl().then(res => {
                    dataArr = res.rows
                    sessionStorage.setItem('dataArr', JSON.stringify(res.rows))
                    this.msgChange(dataArr)
                })
            } else {
                dataArr = JSON.parse(sessionStorage.getItem('dataArr'))
                this.msgChange(dataArr)

            }
        },
        // 数据处理
        msgChange(arr) {
            let arcData = []
            let someData = []
            let areaNews = []
            let dataArr = arr
            dataArr.forEach(element => {
                if (element.publishTime != null) {
                    const dateStr = element.publishTime;
                    const dateRegex = /^(\d{4})-(\d{2})-(\d{2})/;
                    const match = dateStr.match(dateRegex);
                    const formattedDate = `${match[1]}/${match[2]}/${match[3]}`;
                    element.publishTime = formattedDate
                }
                element.coverImage = element.coverImage == null ? null : `${process.env.VUE_APP_BASE_API}` + element.coverImage

                if (element.category != null && element.coverImage != null && element.category.parentName == '畅游花果山') {
                    arcData.push(element)
                } else if (element.category != null && element.coverImage != null && element.category.categoryName == '文化花果山') {
                    someData.push(element)
                } else if (element.category != null && element.coverImage != null && element.category.parentName == '景区资讯') {
                    element.mounth = element.category.createTime.split(' ')[0].split('-')[0] + '-' + element.category.createTime.split(' ')[0].split('-')[1]
                    element.day = element.category.createTime.split(' ')[0].split('-')[2]
                    areaNews.push(element)
                }
            });
            axios.post(`${process.env.VUE_APP_BASE_API}/door/ArticleList?categoryId=2`, {
            })
            .then( (response)=> {
                let noticeArr = response.data.rows
                noticeArr.forEach(element=>{
                    if(element.publishTime){
                        element.mounth = element.publishTime.split(' ')[0].split('-')[0] + '-' + element.publishTime.split(' ')[0].split('-')[1]
                        element.day = element.publishTime.split(' ')[0].split('-')[2]
                    }
                })
                this.newsArea = this.newsArea.concat(noticeArr.slice(0, 3))

                axios.post(`${process.env.VUE_APP_BASE_API}/door/ArticleList?categoryId=3`, {
                })
                .then( (response)=> {
                    let noticeArr = response.data.rows
                    noticeArr.forEach(element=>{
                       if(element.publishTime){
                            element.mounth = element.publishTime.split(' ')[0].split('-')[0] + '-' + element.publishTime.split(' ')[0].split('-')[1]
                            element.day = element.publishTime.split(' ')[0].split('-')[2]
                        }
                    })
                    this.newsArea = this.newsArea.concat(noticeArr.slice(0, 3))
                })
            })
            
            for (let i = 0; i < 5; i++) {
                const element = someData[i];
                element.url = require(`../img/999-${i + 1}.png`)
            }
            this.squireList = someData.splice(0, 5)
            arcData.forEach(element => {
                if (element.category != null && element.description != null && element.category.categoryName == '美食') {
                    this.foodArr.push(element)
                } else if (element.category != null && element.description != null && element.category.categoryName == '住宿') {
                    this.bedArr.push(element)
                } else if (element.category != null && element.description != null && element.category.categoryName == '交通') {
                    this.busArr.push(element)
                } else if (element.category != null && element.description != null && element.category.categoryName == '游玩') {
                    this.outingArr.push(element)

                } else if (element.category != null && element.description != null && element.category.categoryName == '特产') {
                    this.goodsArr.push(element)
                } else if (element.category != null && element.description != null && element.category.categoryName == '娱乐') {
                    this.playArr.push(element)
                }
            });
            let data = []
            data[0] = this.foodArr[0]
            data[1] = this.bedArr[0]
            data[2] = this.outingArr[0]
            data[3] = this.goodsArr[0]
            data[4] = this.outingArr[1]
            data[5] = this.playArr[2]
            this.msgList = data



        },
        getDynamicTourist(){
            axios.get(`${process.env.VUE_APP_BASE_API}/door/dynamicTourist`).then((res) => {
                this.dynamicTourist = res.data
        })

        },
        scrollLinster() {
            const element = document.querySelectorAll('.page')[4];
            // 添加滚轮事件监听器
            element.addEventListener('wheel', handleScroll);
            // 滚轮事件处理函数
            function handleScroll(event) {
                const scrollTop = element.scrollTop;
                if (scrollTop > 0) {
                    event.stopPropagation();
                }
            }
        },
        handleResize() {
            location.reload()
        },
        changeTab(index) {
            this.tabIndex = index
        },
        hideBox() {
            this.$refs.testBox.style.marginTop = '5vw'
            this.$refs.testBox.style.opacity = '0'
        },
        // 跳转文章详情页
        toDetails(item) {
            this.$router.push({
                path: '/outinghgs',
                query: {
                    // nowPage: this.tabIndex,
                    atcleId: item.id
                }
            })
        },
        // 跳转
        itemDetail(item, index) {
            if (item.category.categoryName == '通知公告') {
                this.$router.push({
                    path: '/information',
                    query: {
                        nowPage: 0,
                        atcId: item.id
                    }
                })
            } else {
                this.$router.push({
                    path: '/information',
                    query: {
                        nowPage: 1,
                        atcId: item.id
                    }
                })
            }
        },
        // 
        toDetail() {

            this.$router.push('/enterhgs')
        },
        toCulture(item) {
            this.$router.push({
                path: '/culture',
                query: {
                    id: item.id
                }
            })

        },
        toPages(index) {
            this.$router.push({
                path: '/outinghgs',
                query: {
                    nowPage: index
                }
            })
        },
        rightScroll() {
            console.log('right');
            if (this.scrollNum > -40) {
                this.scrollNum = this.scrollNum - 20
                document.querySelector('.thirdBox').style.left = `${this.scrollNum}vw`

            }
        },
        leftScroll() {
            console.log('left');
            if (this.scrollNum < 0) {
                this.scrollNum = this.scrollNum + 20
                document.querySelector('.thirdBox').style.left = `${this.scrollNum}vw`

            }
        },
    }
};
</script>
<style scoped>
@font-face {
    font-family: 'FontName';
    src: url('../../font/仓耳青禾体 w05.ttf');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'kaiti';
    src: url('../../font/nzgrRuYinZouZhangKai.ttf');
    font-style: normal;
    font-weight: normal;
}

.video-container {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.videoPlayer {
    display: block;
    width: 100vw !important;
    height: 100vh !important;
    object-fit: fill
}

.msgTab {
    /* width: 90vw; */
    /* height: 8vw; */
    /* height: 13vh; */
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    position: absolute;
    top: 80%;
    z-index: 9999;
}

.msgItem {
    width: 20%;
    display: flex;
    padding: 1vw;
    cursor: pointer;
}

.msgItem img {
    margin-right: 1vw;
}

.left img {
    width: 4vw;
    margin-top: 1vw;
}

.right {
    width: 10vw;
    height: 5vw;
    padding-top: 1vw;
}

.right h2 {
    font-weight: 400;
    font-size: 1.5vw;
    margin-bottom: .5vw;
    display: flex;
}

.sun {
    width: 1.5vw;
    height: 1vw;
    margin: 0.5vw 0.3vw  !important;
    display: inline-block;
}

.right span {
    display: block;
    height: 1vw;
    font-size: .8vw;
    white-space: nowrap;
    color: #828280;
}

.right span span {
    display: inline-block;
}

.scroll {
    width: 25vw;

    background-size: 100% 100%;
    background-image: url('../img/图层 944.png');
}

.msgBox {
    display: flex;
    margin-left: 10vw;
    margin-top: 3vw;
    margin-right: 6vw;
}

.arror {
    /* padding-top: .2vw; */
    margin-right: 1vw;
    position: relative;
    top: -0.5vw;
}

.arror img {
    width: 1vw;
    height: 0.5vw;
    display: block;
    margin: 0.2vw 0 1.5vw 0;
}

.msgBox p {
    height: 2vw;
    white-space: nowrap;
    line-height: 2vw;
    font-size: 1vw;
    color: #fff;
}

.mouse {
    width: 1.5vw;
    height: 2vw;
    margin-left: 1vw;
}

.page5 {
    /* height: 100vh; */
    /* padding-bottom: 10vw; */
    position: relative;

}


/* 第二屏 */
.titleBox {
    width: 80vw;
    text-align: center;
    margin: 0vw auto 5vw;
    padding-top: 7vw;
    position: relative;
    /* bottom: 3vw; */
    /* opacity: 0; */
    transition: all .5s linear;
    /* background-color: aqua; */
}

.titleBox h2 {
    font-weight: 200;
    font-family: 'FontName';
    font-size: 2.5vw;
    display: flex;
    color: #383838;
    justify-content: center;
}

.titleBox h2 p {
    width: .1vw;
    height: 2.5vw;
    margin: .3vw 1vw 0;
    background-color: #C3A05B;
}

.titleBox span {
    font-size: 1vw;
    display: block;
    color: #7C5404;
    font-weight: 400;
}

.secondBox {
    display: flex;
}

.boxLeft {
    width: 50%;
    /* height: 10vw; */
    padding: 0 5vw;
    box-sizing: border-box;
    /* background-color: aqua; */
}

.leftTitle {
    margin-bottom: 1.5vw;
    display: flex;
    justify-content: center;
}

.leftTitle img {
    width: 1.3vw;
    height: 1.3vw;
    margin: .5vw .2vw 0 0;
}

.leftTitle p {
    font-size: 2vw;
    font-family: 'FontName';
    color: #5A5A5A;
}

.leftMsg p {

    font-size: 1vw;
    line-height: 1.7vw;
    padding: 0 0 0 5vw;
    margin-bottom: 1vw;
    color: #3C3B3B;
}

.learnMore {
    margin-left: 5vw;
    font-size: 1vw;
    color: #A3771F;
    cursor: pointer;
}

.smallVideo {
    width: 75%;
    display: block;
    /* margin-left: 10vw; */
    margin: 0 auto;
    box-shadow: 1vw .5vw 0vw #67BE97;
}

.boxRight {
    width: 40%;
    /* height: 10vw; */
    /* background-color: #C3A05B; */
}

.tabBox {
    margin-bottom: 2vw;
    display: flex;
    justify-content: center;
    /* opacity: 0; */
    transition: all 1s linear;
}

.tabItem {
    margin: 0 1vw;
    cursor: pointer;
}

.tabItem img {
    width: 5vw;
}

.tabItem p {

    font-size: 1.2vw;
    color: #fff;
    text-align: center;
}

.lscrollBox {
    width: 80vw;
    margin: 0 auto;
    /* display: flex; */
    overflow-x: scroll;
    position: relative;
}

.leftArr {

    z-index: 9999;

    /* background-color: #fff; */
    position: absolute;
    left: 6vw;
    top: 30vw;
}

.leftArr img {
    width: 100%;
    height: 100%;
}

.rightArr {

    z-index: 9999;
    /* background-color: #fff; */
    position: absolute;
    /* right: 0; */
    left: 92vw;
    top: 30vw;

}

.rightArr img {
    width: 100%;
    height: 100%;
}

.thirdBox {
    position: relative;
    left: 0;
    width: 120vw;
    display: flex;
    /* justify-content: space-around; */
    /* padding: 0 5vw; */
    /* margin-top: 5vw; */
    /* opacity: 0; */
    transition: all .5s linear;
}

.boxItem {
    width: 17vw;
    padding: .5vw;
    margin: 0 1vw;
    background-color: #fff;
}

.boxItem img {
    width: 100%;
    /* width: 18vw; */
    height: 11vw;
    /* display:; */
}

.boxItem h2 {
    white-space: nowrap;
    padding-right: 1vw;
    height: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.5vw;
    font-weight: normal;
    color: #2B2B2B;
    margin: .5vw 0;
}

.boxItem p {
    height: 3vw;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #7D7D7D;
    font-size: 1vw;
    line-height: 1.5vw;
}

.thirdMore {
    font-size: 1vw;
    cursor: pointer;
    margin: .5vw 0;
    text-align: right;
    color: #4BB0AC;
}

.fourBox {
    width: 70vw;
    height: 35vw;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    /* justify-content: space-around; */
    background-image: url('../img/图层 996.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}



.fourItem {
    width: 17vw;
    height: 17vw;
    margin: 3vw 0 0 4vw;
    position: absolute;
}

.itemBox {
    width: 13vw;
    height: 13vw;
    transform: rotateZ(-45deg);
    margin: 2vw auto;
    box-sizing: border-box;
    /* background-image: url('../img/组 13 拷贝 3.png'); */
    background-size: 100% 100%;
    overflow: hidden;
}

.itemP {
    width: 1vw;
    height: 11vw;
    padding: 4vw 0.5vw 5vw;
    font-size: 1.1vw;
    position: relative;
    left: 3.8vw;
    top: -5vw;
    background-size: cover;
    z-index: 999;
    color: #fff;
    font-family: 'kaiti';
    transform: rotateZ(45deg);
    transform-origin: 50% 50%;
}

.fifthBox {
    margin-top: 3vw;
    padding-left: 9.5vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.fifthItem {
    width: 42%;
    display: flex;
    /* margin: 1.5vw; */
    margin: 1vw 1.5vw 1vw 0;
    padding: 1vw .8vw;
    background-color: #fff;
    cursor: pointer;
}

.itemLeft {
    width: 10vw;
    height: 5vw;
    /* padding: 1vw; */
    color: #fff;
    background-color: #429D7F;
    margin-right: 1vw;
    text-align: center;
}

.day {
    font-size: 2vw;
    padding-top: .5vw;

}

.month {
    font-size: .8vw;
}

.itemright {
    /* padding-right: 5vw; */
    font-size: .8vw;
    /* padding-right: 5vw; */
}

.title {
    font-size: 1vw;
    color: #242424;
    margin-bottom: .5vw;
}

.tips {
    box-sizing: border-box;
    line-height: 1vw;
    color: #585858;
    padding-right: 5vw;
}

.pageMsg {
    height: 100vh;
    background-image: url('@/img/fifthBack.png');
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-size: 100% 100%;
}

.trasBox {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    margin: 0 100px;
    /* transition: all 1s linear; */
    /* position: relative; */
    white-space: nowrap;
    /* 防止内容换行 */
    animation: scrollAnimation 10s linear infinite;
    /* 定义滚动动画 */
    /* top: 0px;
    left: 0px; */
}

.showTrans {
    /* position: absolute; */
    width: 9.5vw;
    overflow-x: hidden;
    display: flex;

}
.timeDetail{
    color: #000000 !important;
    border-bottom: .1vw solid #727272;
}

@keyframes scrollAnimation {
    0% {
        transform: translateX(0);
        /* 初始位置 */
    }

    100% {
        transform: translateX(-100%);
        /* 滚动到最右侧 */
    }
}
</style>