<template>
    <div class="sideBox">
        <div class="sideItem" ref="sideItem" v-for="(item, index) in sideList" :key="index" @mouseleave="sideLeave(index)"
            @mouseenter="sideEnter(index, item)" @click="clickSide(item, index)">
            <img :src="require('../img/' + item.url)" alt="">
            <p>{{ item.value }}</p>
        </div>
    </div>
</template>
<script>
import { getList } from '@/api/apis'
export default {
    name: 'sideBox',
    data() {
        return {
            test: '',
            // 当前语言
            nowLang: '',
            sideList: [
                { value: '旅游团预约', url: 'group.png' },
                { value: 'VR景区', url: 'vr.png' },
                { value: '导游预约', url: 'group.png' },
                { value: '问卷调查', url: 'wenjuan.png' },
                { value: '语音导览', url: 'customer-service.png' },
            ],
        }
    },
    mounted() {
        this.nowLang = sessionStorage.getItem('lang') == undefined ? '0' : sessionStorage.getItem('lang')
        // this.getSide()
    },
    methods: {
        // getSide() {
        //     getList(this.nowLang).then(res => {
        //         // console.log(res);
        //     })
        // },
        sideEnter(index) {
            this.$refs.sideItem[index].style.backgroundColor = '#64BB94'
        },
        sideLeave(index) {
            this.$refs.sideItem[index].style.backgroundColor = '#989797'
        },
        clickSide(item, index) {
            console.log(item, index);
            if (index == 0) {
                window.location.href = 'http://team.lyghgs.cn/background/index.html#/login?redirect=%2Fdashboard'
            }
            if (index == 1) {
                window.location.href = 'https://6a6hpzlhf.wasee.com/wt/6a6hpzlhf'
            }
            if (index == 2) {
                this.$router.push('/bookguide')
            }
            if (index == 3) {
                window.location.href = 'http://quesj.jsxinyuansu.com/#/about'
            }
            if (index == 4) {
                this.$router.push('/audio')
            }
            window.scrollTo({
                top: 0,
            });
        }
    }

}
</script>
<style scoped>
.sideBox {
    /* width: 10vw; */
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 25%;
}

.sideItem {
    width: 3vw;
    height: 3vw;
    background-color: #989797;
    border-radius: 50%;
    margin-bottom: 2vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: .5vw;
    cursor: pointer;
    box-sizing: content-box;
}

.sideItem p {
    display: block;
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: .6vw;
    white-space: nowrap;
    color: #fff;
}

.sideItem img {
    max-height: 60%;
    display: block;
    margin: 0 auto;
}
</style>