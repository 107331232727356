import request from "./requset";


// 获取菜单
export function getList(langType) {
  return request({
    url: `/door/categoryTags?categoryTags=${langType}`,
    method: 'get',
  })
}

// 获取文章
export function getAtl() {
  return request({
    url: `/door/ArticleList`,
    method: 'post',
  })
}

// 获取文章详情
export function getdetail(id) {
  return request({
    url: `/door/getArt/${id}`,
    method: 'post',
  })
}

// 导游预约
export function addTable(data) {
  console.log(data);
  return request({
    url: `/door/MakeAnAppointmentAdd?fromCity=${data.fromCity}&fromProvince=${data.fromProvince}&guiderId=${data.guiderId}&guiderName=${data.guiderName}&idCard=${data.idCard}&makeDate=${data.makeDate}&makeLinkman=${data.makeLinkman}&makePhone=${data.makePhone}`,
    method: 'post',
    // data: data
  })
}

// /door/FuzzySearch?keywords=%E7%8C%B4
// 获取图片
// 
export function searchRes(word) {
  // console.log(data);
  return request({
    url: `/door/FuzzySearch?keywords=${word}`,
    method: 'post'
  })
}
// 获取图片
// 
export function getPic() {
  // console.log(data);
  return request({
    url: `/door/MaterialList`,
    method: 'post'
  })
}

// 获取导游信息
// 
export function getGuider() {
  // console.log(data);
  return request({
    url: `/door/getGuiderInfo`,
    method: 'post'
  })
}

//提交信箱
export function pushMail(data) {
  // console.log(data);
  // console.log(data);
  return request({
    url: `/door/addMailbox?content=${data.content}&emailAddr=${data.emailAddr}&phoneNumber=${data.phoneNumber}&theme=${data.theme}&userName=${data.userName}`,
    method: 'post',
    // data: data

  })
}
// 提交建议
export function pushSuggest(data) {
  // console.log(data);
  return request({
    url: `/door/addSuggest?content=${data.content}&emailAddr=${data.emailAddr}&phoneNumber=${data.phoneNumber}&theme=${data.theme}&userName=${data.userName}`,
    method: 'post',
    data: data

  })
}