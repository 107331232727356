<template>
    <div class="footerBox">
        <div class="footTop">
            <div class="topLeft">
                <div class="leftItem" v-for="(item, index) in phoneArr" :key="index">
                    <span>{{ item.type }}</span>
                    <span>{{ item.phoneNum }}</span>
                </div>
            </div>
            <div class="topRight">
                <div class="rightItem" v-for="(item, index) in codeArr" :key="index">
                    <div class="bigBox" ref="bigBox">
                        <img :src="require('../img/' + item.bigImg)" alt="">
                    </div>
                    <div class="smallBox" @mouseenter="showbigBox(index)" @mouseleave="hidebigBox(index)">
                        <img :src="require('../img/' + item.url)" alt="">
                        <span>{{ item.value }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="line"></div>
        <div class="footBottom">
            <img src="../img/图层 1009@2x.png" alt="">
            <div class="msgs">
                <p>版权所有: 连云港市花果山风景区管理处</p>
                <p> 地址: 连云港市郁林路5号 </p>
                <p>花果山游客服务热线:0518-85723837 </p>
                <p>免责声明 技术支持：江苏新元素数字科技有限公司</p>
            </div>
            <div class="lastp">
                <!-- <p>Power by DedeCms</p> -->
                <p>苏ICP备18039045号-1</p>
                <p>苏公网安备 32070502010353号</p>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'footerBox',
    data() {
        return {
            phoneArr: [
                {
                    type: '咨询热线',
                    phoneNum: '0518-85723839'
                },
                {
                    type: '投诉热线',
                    phoneNum: '0518-85723837'
                },
                {
                    type: '救援热线',
                    phoneNum: '0518-85721235'
                },
            ],
            codeArr: [
                { value: '微信公众号', url: '微信公众号（小）.jpg', bigImg: '微信公众号（大）.jpg' },
                { value: '微信视频号', url: '微信视频号（小）.jpg', bigImg: '微信视频号（大）.jpg' },
                { value: '抖音', url: '抖音（小）.jpg', bigImg: '抖音（大）.jpg' },
                // { value: '微博', url: '图层 1015.png',bigImg:'图层 1015.png' },
            ],
            langType: ''
        }
    },
    mounted() {
        // console.log(sessionStorage.getItem('lang '));
        this.langType = sessionStorage.getItem('lang')
        if (this.langType == '1') {
            this.phoneArr = [
                {
                    type: 'Complaints',
                    phoneNum: '0518-85723839'
                },
                {
                    type: 'Information',
                    phoneNum: '0518-85723837'
                },
                {
                    type: 'Rescue',
                    phoneNum: '0518-85721235'
                },
            ]
        } else if (this.langType == '3') {

            this.phoneArr = [
                {
                    type: '情報',
                    phoneNum: '0518-85723839'
                },
                {
                    type: '苦情',
                    phoneNum: '0518-85723837'
                },
                {
                    type: '救援',
                    phoneNum: '0518-85721235'
                },
            ]

        } else if (this.langType == '2') {

            this.phoneArr = [
                {
                    type: '정보 핫라인',
                    phoneNum: '0518-85723839'
                },
                {
                    type: '불만 핫라인',
                    phoneNum: '0518-85723837'
                },
                {
                    type: '구조 전화',
                    phoneNum: '0518-85721235'
                },
            ]

        }
    },
    methods: {
        showbigBox(index){
            console.log();
            this.$refs.bigBox[index].style.display = 'block'
        },
        hidebigBox(index){
            console.log();
            this.$refs.bigBox[index].style.display = 'none'
        }
    }

}
</script>
<style scoped>
.footerBox {
    position: relative;
    /* top: 100vh; */
    background-color: #272831;
    padding: 16vh 11vw;
    padding-right: 0;
    padding-bottom: 0;
}

.footTop {
    display: flex;
    justify-content: space-between;
    width: 87%;
}

.topLeft {
    width: 50%;
    display: flex;
    /* margin-right: 1vw; */
}

.leftItem {
    margin-right: 5vw;
}

.leftItem span {
    display: block;
    color: #9B9B9B;
    font-weight: 600;
}

.leftItem span:nth-child(1) {
    width: 60%;
    font-size: 1.2vw;
    color: #9A895F;
    border-bottom: .3vh solid #9a895f77;
    padding-bottom: 1.2vh;
}

.leftItem span:nth-child(2) {
    padding-top: 1vh;
    font-size: 1.5vw;
    white-space: nowrap;
}

.topRight {
    margin-left: 5vw;
    display: flex;
    justify-content: space-around;
}

.rightItem {
    margin-left: 3vw;
    position: relative;
}

.rightItem span {
    display: block;
    line-height: 1.8vh;
    font-size: .9vw;
    text-align: center;
    color: #fff;
}

.rightItem img {
    width: 4.5vw;
}

.line {
    width: 90vw;
    margin: 6vh -6vw;
    border-bottom: .1vh solid #e1ddd72a;
}

.footBottom {
    width: 90%;
    padding-bottom: 3vh;
}

.footBottom img {
    width: 40vw;
    margin: 0 auto;
    display: block;
}

.msgs {
    /* width: 90%; */
    margin: 5vh 4vw 0;
    display: flex;
}

.msgs p {
    font-size: 1vw;
    margin: 0;
    margin-right: 1vw;
    color: #959595;
    cursor: pointer;
    white-space: nowrap;
}

.lastp {
    /* width: 50%; */
    display: flex;
    justify-content: center;
    color: #959595;
    margin: 0 auto;
    font-size: 1vw;
    /* margin: 1vh 0; */
    cursor: pointer;
}

.lastp p {
    margin-right: 1vw;
}

.bigBox{
    display: none;
    width: 10vw;
    /* height: 10vw; */
    background-color: #fff;
    position: absolute;
    bottom: 6vw;
    left: -2.5vw;
}
.bigBox img{
    width: 90%;
    display: block;
    margin: .1vw auto;
}
</style>