import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../Pcviews/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/bookguide',
    name: 'bookguide',
    props: true,
    component: () => import('../Pcviews/Bookguide.vue')
  },
  {
    path: '/enterhgs',
    name: 'enterhgs',
    props: true,
    component: () => import('../Pcviews/enterHgs.vue')
  },
  {
    path: '/chiefPage',
    name: 'chiefPage',
    props: true,
    component: () => import('../Pcviews/chiefPage.vue')
  },
  {
    path: '/outinghgs',
    name: 'outinghgs',
    props: true,
    component: () => import('../Pcviews/outingHgs.vue')
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('../Pcviews/Information.vue')
  },

  {
    path: '/culture',
    name: 'culture',
    component: () => import('../Pcviews/culturePage.vue')
  },
  {
    path: '/audio',
    name: 'audio',
    component: () => import('../Pcviews/Audioguide.vue')
  },
  {
    path: '/otherLang',
    name: 'otherLang',
    component: () => import('../Pcviews/otherLang.vue')

  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import('../Pcviews/newsCenter.vue')

  },{
    path: '/openTime',
    name: 'openTime',
    component: () => import('../Pcviews/openTime.vue')

  },



  // aboutHgs
  {
    path: '/langEnter',
    name: 'langEnter',
    component: () => import('../Pcviews/otherLangs/aboutHgs.vue')

  },

  {
    path: '/langOuting',
    name: 'langOuting',
    component: () => import('../Pcviews/otherLangs/outingHgs.vue')

  }, {
    path: '/langCulture',
    name: 'langCulture',
    component: () => import('../Pcviews/otherLangs/cultureHgs.vue')

  },
  {
    path: '/searchRes',
    name: 'searchRes',
    component: () => import('../Pcviews/searchRes.vue')

  },
  {
    path: '/scenery',
    name: 'scenery',
    component: () => import('../Pcviews/sceneryPage.vue')

  },
  {
    path: '/report',
    name: 'report',
    component: () => import('../Pcviews/report.vue')

  }
]

const router = new VueRouter({
  routes
})

export default router
