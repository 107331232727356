<template>
    <div class="container">
        <div class="logo">
            <img src="../img/logo.png" alt="">
        </div>
        <div class="navs">
            <div class="firstMenu">
                <div class="menuItem" v-for="(item, index) in navList" :key="index">
                    <span @mouseenter="enterMenu(index, item)" @click="testLink(item, index)">
                        <!-- <span @mouseenter="enterMenu(index, item)"> -->
                        <span>{{ item.categoryName }}</span>
                        <p class="triangle" v-show="index == secontrol"></p>
                    </span>
                    <div class="seItems" v-show="index == secontrol" @mouseleave="leaveMenu()">
                        <div v-for="(seitem, seindex) in item.category" :key="seindex">
                            <span @click="seLink(seindex, index)">{{ seitem.categoryName }}</span>
                            <p>|</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="navsMask" v-show="maskShow"></div>
        <div class="navCol">
            <div class="traslate" @click="showLangs">
                <span style="font-size: 1.2vw;margin-left: -1vw;text-align: center;">{{ nowLang == '0' ? '中文' : nowLang ==
                    '1' ? 'English' : nowLang == '3' ? '日本語' : '한국어'
                }}</span>
                <p class="trastriangle"></p>
            </div>
            <div class="otherLang" v-show="showLang">
                <span v-show="item.status == nowLang ? false : true" v-for="(item, index) in langList" :key="index"
                    @click="changeLang(item)">{{ item.name }}</span>
            </div>
            <div class="line">|</div>
            <div class="search" @click="searchShow">
                <img src="../img/图层 925@2x.png" alt="">
            </div>
            <div class="searchBox" v-show="showSearch">
                <input type="text" v-model="searchWord">
                <img style="width: 1vw;height: 1vw;padding: .5vw;" src="../img/图层 925@2x.png" alt="" @click="searchItem()">

            </div>
        </div>
    </div>
</template>
<script>
import { getList } from '@/api/apis'
export default {
    name: 'NavView',
    data() {
        return {
            searchWord: '',
            showSearch: false,
            nowLang: '中文',
            langList: [
                {
                    name: '中文',
                    status: '0'
                },
                {
                    name: 'English',
                    status: '1'
                },
                {
                    name: '한국어',
                    status: '2'
                },
                {
                    name: '日本語',
                    status: '3'
                }
            ],
            showLang: false,
            navList: [],
            otherLang: [
                { value: '홈' },
                { value: '화과산 소개' },
                { value: '마음껏 화과산 관람' },
                { value: '문화 화과산' },
                { value: '사이버 관광' },
            ],
            // 控制二级菜单显隐
            secontrol: 999,
            maskShow: false
        }
    },
    mounted() {

        this.nowLang = sessionStorage.getItem('lang') == undefined ? '0' : sessionStorage.getItem('lang')
        this.getData()

    },
    methods: {
        // 获取菜单列表
        getData() {
            getList(this.nowLang).then(res => {
                let dataArr = res
                let finArr = dataArr.slice(0, 5)
                dataArr.forEach(ele => {
                    if (ele.categoryName == 'VR景区' || ele.categoryName == '语音导览') {
                        finArr.push(ele)
                    }
                });
                if (this.nowLang == '0') {
                    finArr.unshift({
                        categoryName: '首页'
                    })
                    // 发送请求获取对应的导航列表
                } else if (this.nowLang == '1') {
                    finArr.unshift({
                        categoryName: 'Home'
                    })
                    // 发送请求获取对应的导航列表
                } else if (this.nowLang == '2') {
                    finArr.unshift({
                        categoryName: '홈'
                    })

                } else if (this.nowLang == '3') {
                    finArr.unshift({
                        categoryName: 'トップページ'
                    })
                }
                if (this.nowLang != '0') {
                    finArr.forEach(ele => {
                        ele.category = null
                    })
                }
                this.navList = finArr
            })

        },
        enterMenu(index, item) {
            if (item.category) {
                let seNum = item.category.length
                if (seNum != 0) {
                    this.secontrol = index
                    this.maskShow = true
                } else {
                    this.maskShow = false
                    this.secontrol = 999
                }
            } else {
                console.log('none');
            }

        },
        leaveMenu() {
            this.secontrol = 999
            this.maskShow = false
        },
        seLink(seindex, index) {
            if (index == 1 && this.nowLang == '0') {
                // this.$router.push('/target-page')
                this.$router.push({
                    path: '/information',
                    query: {
                        nowPage: seindex
                    }
                })
            } else if (index == 2 && this.nowLang == '0') {
                this.$router.push({
                    path: '/newsCenter',
                    query: {
                        nowPage: seindex
                    }
                })
            } else if (index == 3 && this.nowLang == '0') {
                this.$router.replace({
                    path: '/enterhgs',
                    query: {
                        nowPage: seindex
                    }
                }).then(() => {
                    location.reload()
                })

            } else if (index == 4 && this.nowLang == '0') {
                this.$router.push({
                    path: '/outinghgs',
                    query: {
                        nowPage: seindex
                    }
                })
            } else if (index == 5 && this.nowLang == '0') {
                this.$router.push({
                    path: '/culture',
                    query: {
                        nowPage: seindex
                    }
                })
            } else if (index == 7 && this.nowLang == '0') {
                this.$router.push({
                    path: '/audio',
                    query: {
                        nowPage: seindex
                    }
                })
            }
            setTimeout(() => {
                location.reload()
                window.scrollTo({
                    top: 0,
                });
            }, 500);
        },
        testLink(item, index) {
            if (this.nowLang == 0) {
                if (index == 0 && this.$route.path != '/') {
                    this.$router.push('/')
                    location.reload()
                } else if (index == 1 && this.nowLang == '0' && this.$route.path != '/information') {
                    this.$router.push('/information')
                } else if (index == 2 && this.nowLang == '0' && this.$route.path != '/chiefPage') {
                    this.$router.push('/chiefPage')
                } else if (index == 3 && this.nowLang == '0' && this.$route.path != '/enterhgs') {
                    this.$router.push('/enterhgs')
                } else if (index == 4 && this.nowLang == '0' && this.$route.path != '/outinghgs') {
                    this.$router.push('/outinghgs')
                } else if (index == 5 && this.nowLang == '0' && this.$route.path != '/culture') {
                    this.$router.push('/culture')
                } else if (index == 6 && this.nowLang == '0') {
                    // this.$router.push('https://6a6hpzlhf.wasee.com/wt/6a6hpzlhf')
                    window.location.href ='https://6a6hpzlhf.wasee.com/wt/6a6hpzlhf'
        

                } else if (index == 7 && this.nowLang == '0' && this.$route.path != '/audio') {
                    this.$router.push('/audio')
                }
            } else if (this.nowLang != 0) {
                if (index == 0 && this.$route.path != '/otherLang') {
                    this.$router.push('/otherLang')
                } else if (index == 1 && this.$route.path != '/langEnter') {
                    this.$router.push('/langEnter')
                } else if (index == 2 && this.$route.path != '/langOuting') {
                    this.$router.push('/langOuting')
                } else if (index == 3 && this.$route.path != '/langCulture') {
                    this.$router.push('/langCulture')
                }else if (index == 4 ) {
                    window.location.href ='https://6a6hpzlhf.wasee.com/wt/6a6hpzlhf'

                }

                // langEnter
            }
            window.scrollTo({
                top: 0,
            });
        },
        showLangs() {
            this.showLang = !this.showLang
        },
        changeLang(item) {
            this.nowLang = item.status
            sessionStorage.setItem('lang', item.status)
            this.showLang = !this.showLang
            if (this.nowLang != '0' && this.$route.path != '/otherLang') {
                sessionStorage.setItem('lang', item.status)
                this.$router.push('/otherLang')
                this.navList = this.otherLang
            } else if (this.nowLang != '0' && this.$route.path == '/otherLang') {
                sessionStorage.setItem('lang', item.status)
                location.reload()
                this.navList = this.otherLang
            } else {
                this.$router.push('/')
            }
        },
        searchShow() {
            this.showSearch = !this.showSearch
        },
        searchItem() {
            this.$router.push({
                path: '/searchRes',
                query: {
                    searchWord: this.searchWord
                }
            })
            setTimeout(() => {
                location.reload()
            }, 1000);
        },
    }
}
</script>
<style  scoped>
.container {
    width: 100vw;
    height: 8vh;
    z-index: 9999;
    padding: 0 7vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    background-color: #2f2f2f9a;

}

.logo {
    width: 12vw;
    /* height: 8vh; */
    display: flex;
    align-items: center;
    margin: 0;
}

.logo img {
    width: 100%;
    /* height: 60%; */
    display: block;
}

.navs {
    width: 60vw;
    height: inherit;
    margin: 0 4vw;
}

.navsMask {
    width: 100vw;
    height: 6vh;
    position: fixed;
    top: 8vh;
    left: 0;
    background-color: #2f2f2f5e;
    border-top: 1px solid #ffffff8a;
}

.firstMenu {
    height: inherit;
    display: flex;
    justify-content: space-around;
    line-height: 8vh;
}

.menuItem {
    font-size: 1.2vw;
    color: #F9F8F7;
    position: relative;
    cursor: pointer
}

.triangle {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 40%;
    border: rgba(255, 255, 255, 0) .4vw solid;
    border-bottom: .4vw solid #cfcecd;
}

.seItems {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: -50%;
    z-index: 999;
}

.seItems div {
    font-size: .9vw;
    white-space: nowrap;
    display: block;
    line-height: 6vh;
}

.seItems div p {
    display: inline;
    margin: 0 1vw;
    color: #ffffff6b;
}

.seItems div:last-of-type>p {
    display: none;
}

.navCol {
    width: 10vw;
    height: inherit;
    line-height: 8vh;
    display: flex;
    position: relative;
}

/* 切换语言按钮 */
.traslate {
    width: 40%;
    height: 8vh;
    display: flex;
    position: relative;
}

.traslate span {
    color: #fff;
    font-size: 1.2vw;
    cursor: pointer
}

.trastriangle {
    width: 0;
    height: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 45%;
    border: rgba(255, 255, 255, 0) .4vw solid;
    border-top: .4vw solid #ffffff;
}

.line {
    font-size: 1vw;
    color: #fff;
    margin: 0 1vw;
}

.search {
    /* align-items: center; */
    width: 4vw;
    height: 2vh;
    position: absolute;
    right: 0;
    bottom: 2.8vh;
    /* display: flex; */
    /* margin-top: 3vh; */
}

.search img {
    /* max-width: 100%; */
    max-height: 100%;
    display: block;
}

.otherLang {
    position: absolute;
    top: 4.1vw;
    right: 6vw;
    background-color: #2f2f2f5e;
}

.otherLang span {
    cursor: pointer;
    padding: 0 .5vw;
    display: block;
    white-space: nowrap;
    /* height: 2vw; */
    /* margin: 1vw 0;*/
    margin: .5vw 0;
    font-size: 1.2vw;
    line-height: 2vw;
    color: #fff;
}

.searchBox {
    /* display: none; */
    padding: 1vw;
    background-color: #464646;
    position: fixed;
    top: 4.5vw;
    right: 1vw;
    display: flex;

}
</style>